:local(.packageWrapper) {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  border: 2px solid #fff;
}

:local(.packageWrapper):local(.selected) {
  border-color: #7975be;
}

:local(.scaffold) {
  width: 100%;
  height: 100%;
  composes: space-col from global;
  composes: base from global;
  composes: inside from global;
  justify-content: space-between !important;
}

:local(.titleWrapper) {
  composes: space-row from global;
  display: flex;
  gap: 1rem;
  align-items: center;
}

:local .titleWrapper_questionBar_legend {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

:local(.packageIcon) {
  background: url('./package-icon.svg') no-repeat center;
  background-size: contain;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  font-weight: 600;
}

:local(.packageFooter) {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  align-items: center;
  height: var(--input-height);
}

:local(.packageButton) {
  padding-top: 15px;
}

:local(.packageButtons) {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

:local(.packageLimit.variable) {
  flex: 1;
  max-width: 250px;
}
:local(.packageSelected) {
  border: 1px solid gray;
  background-color: white;
  color: black;
  font-size:0.9em;
}

:local(.packageSelected:hover) {
  background-color: white;
}

:local(.removeFromQuoteButton) {
  padding: 0 0.7rem;
  color: black;
  border-color: black;
}

:local(.removeFromQuoteLink) {
  color: black;
  display: flex;
  align-items: center;
  margin-left: auto;
}

:local(.removeFromQuoteIcon) {
  border: 1px solid black;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:local(.removeFromQuoteLink:hover) :local(.removeFromQuoteIcon) {
  border-color: #7975be;
}

:local(.moreInfo) {
  color: #7975be;
  font-weight: 600;

  > * {
    display: inline;
  }
}

:local(.tickIconWrapper) {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: #7975be;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  border-radius: 100%;
  padding: 1.5rem;
}

:local(.inclusionIcon) {
  display: flex;
  margin-left: 50px;
}

/* Icons */

:local(.packageWrapper).stella_q_roadside_assistance :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/Roadside-assistance_black.svg')
    no-repeat center;
  background-size: contain;
}

:local(.headingCentered) {
  justify-content: center;
}


/* Media Queries */
@media screen and (min-width: 750px){
  :local(.mobileOnly) {
    display: none;
  }
}

@media screen and (max-width: 749px) {
  :local(.desktopOnly) {
    display: none;
  }

  :local(.packageWrapper) {
    height: auto;
  }

  :local(.packageSelected),
  :local(.addToQuoteButton) {
    max-width: 270px;
  }

  :local(.tierContainer) {
    flex-direction: column;
  }

  :local(.tierColumn) {
    width: 100%;
  }

  :local(.removeFromQuoteButton) {
    margin-top: 20px;
  }

}

/* @media screen and (max-width: 499px) {
  


} */

@media screen and (min-width: 500px) {
  :local(.packageFooter) {
    justify-content: space-between;
    margin-top: auto;
  }

  :local(.packageLimit) {
    border: none;
  }
}

:local(.tierContainer) {
  display: flex;
  gap: 20px;
  width: 100%;
}

:local(.tierColumn) {
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
}

:local(.tierColumn:hover) {
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

:local(.selectedTier) {
  border-color: #7975be;
  background-color: rgba(121, 117, 190, 0.05);
}

:local(.tierHeader) {
  text-align: center;
  margin-bottom: 20px;
}

:local(.price) {
  font-size: 1.2em;
  color: #666;
}

:local(.inclusionsList) {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

:local(.inclusionRow) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

:local(.inclusionName) {
  flex: 1;
  font-size: 0.95em;
}

:local(.inclusionValue) {
  display: flex;
  align-items: center;
  gap: 4px;
}

:local(.checkIcon) {
  color: green;
}

:local(.crossIcon) {
  color: red;
}

:local(.extraInfo) {
  font-size: 0.9em;
  color: #666;
}

:local(.selectButton) {
  margin-top: 20px;
}
