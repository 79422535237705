:local(.packageWrapper) {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  height: 330px;
  border: 2px solid #fff;
}

:local(.packageWrapper):local(.selected) {
  border-color: #7975be;
}

:local(.titleWrapper) {
  display: flex;
  font-weight: 600;
  gap: 1rem;
  align-items: center;
}

:local(.packageIcon) {
  background: url('./package-icon.svg') no-repeat center;
  background-size: contain;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  font-weight: 600;
}

:local(.packageDescription) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem;
}

:local(.packageFooter) {
  display: flex;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 1rem;
  align-items: center;
  height: var(--input-height);
}

:local(.packageButtons) {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

:local(.packageLimit) {
  border-right: 1px solid #c5b4e2;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

:local(.packageLimit.variable) {
  flex: 1;
  max-width: 250px;
}

:local(.packageSelected) {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 3rem;
  width: 100%;
  justify-content: center;
  font-size: 0.9rem;
  height: calc(var(--input-height) + 2px);
}

:local(.packageSelectedToggle) {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 3rem;
  width: 100%;
  justify-content: center;
  font-size: 0.75rem;
  height: calc(var(--input-height) + 2px);
}

:local(.packageSelected) span {
  display: flex;
  gap: 0.5rem;
}

:local(.addToQuoteButton) {
  flex: 1;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

:local(.addToQuoteButtonToggle) {
  flex: 1;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

:local(.removeFromQuoteButton) {
  padding: 0 0.7rem;
  color: black;
  border-color: black;
}

:local(.removeFromQuoteLink) {
  color: black;
  display: flex;
  align-items: center;
  margin-left: auto;
}

:local(.removeFromQuoteIcon) {
  border: 1px solid black;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:local(.removeFromQuoteLink:hover) :local(.removeFromQuoteIcon) {
  border-color: #7975be;
}

:local(.moreInfo) {
  color: #7975be;
  font-weight: 600;

  > * {
    display: inline;
  }
}

:local(.tickIconWrapper) {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background-color: #7975be;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(40%, -40%);
  border-radius: 100%;
  padding: 1.5rem;
}

/* Icons */
:local(.packageWrapper).stella_q_travel_medical :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/emergency-medical-additional-expenses.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_trip_interruption :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/trip-cancellation-interruption.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_travel_delay :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/travel-delay.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_personal_accident :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/personal-accident.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_personal_liability :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/personal-liability.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_baggage :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/luggage-personal-effects.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_snow :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/snow.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_motorcycle_moped :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/motorcycle-moped.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_cruise_pack :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/cruise.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_pet_boarding :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/pet-boarding.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/travel/car-rental.svg')
    no-repeat center;
}

:local(.packageWrapper).stella_q_roadside_assistance :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/icon/Roadside-assistance_black.svg')
    no-repeat center;
  background-size: contain;
}

:local(.packageWrapper).stella_q_hire_car :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/question-car-hire.cb1.svg')
    no-repeat center;
  background-size: contain;
}

:local(.packageWrapper).stella_q_excess_free_windscreen :local(.packageIcon) {
  background: url('https://src.aventus.app/assets/stella/images/question-windscreen-cover.cb1.svg')
    no-repeat center;
    background-size: contain;
}


/* Rental Vehicle Package Style Overrides */

:local(.packageWrapper).stella_q_rental_vehicle {
  height: auto;
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.packageLimit.variable) {
  max-width: 300px;
  padding-right: 0;
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.packageSelected),
:local(.packageWrapper).stella_q_rental_vehicle :local(.addToQuoteButton) {
  max-width: 270px !important;
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.limitLabel) {
  display: none;
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.packageFooter) {
  flex-direction: column;
  align-items: flex-end;
  height: auto;
}

@media screen and (max-width: 499px) {
  :local(.packageWrapper).stella_q_rental_vehicle :local(.packageFooter) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

:local(.packageWrapper).stella_q_rental_vehicle :local(.packageLimit.variable) {
  border: none;
  align-self: stretch;
}

:local(.priceLoaderContainer) {
  padding-left: 40px;
  padding-top: 5px;
}

:local(.priceLoader) {
  font-size: 4px !important;
}

:local(.packageSectionContainer) {
  display: flex;
  padding: 10px 0;
}

:local(.packageSection) {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  width: 0;
}

:local(.packageSection:first-child) {
  border-right: 2px solid var(--primary);
  padding-right: 20px;
}

:local(.packageSection:last-child) {
  padding-left: 20px;
}

:local(.packageSection > .moreInfo) {
  margin: 10px 0 5px 0;
  display: block;
}

:local(.packageSection > .packageSectionHeader) {
  font-weight: bold;
}

:local(.headingCentered) {
  justify-content: center;
}


/* Media Queries */

@media screen and (max-width: 749px) {
  :local(.packageWrapper) {
    height: auto;
  }

  :local(.packageSelected),
  :local(.addToQuoteButton) {
    max-width: 270px;
  }

  :local(.packageSectionContainer) {
    padding: 10px 0 0;
  }

}

@media screen and (max-width: 499px) {
  :local(.desktopOnly) {
    display: none;
  }

  :local(.packageSectionContainer .mobileOnly) {
    display: block;
  }
}

@media screen and (min-width: 500px) {
  :local(.mobileOnly) {
    display: none;
  }


  :local(.packageFooter) {
    justify-content: space-between;
    margin-top: auto;
  }

  :local(.packageLimit) {
    border: none;
  }
}
